import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal'

export default class extends Controller {
  static values = {
    openTrigger: { type: String, default: 'data-micromodal-trigger' },
    closeTrigger: { type: String, default: 'data-micromodal-close' }
  }

  static targets = ['modal']

  modalTargetConnected () {
    if (this.connected) this.#refreshModals()
  }

  connect () {
    this.#initModals()
    this.connected = true
  }

  show ({ params: { modalId } }) {
    MicroModal.show(modalId)
  }

  close ({ params: { modalId } }) {
    MicroModal.close(modalId)
  }

  #initModals () {
    MicroModal.init({
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: true,
      openTrigger: this.openTriggerValue,
      closeTrigger: this.closeTriggerValue
    })
  }

  // Hack to initialize modals injected via AJAX
  // taken from https://github.com/ghosh/Micromodal/issues/312#issuecomment-618801564
  #refreshModals () {
    const modalTriggers = document.querySelectorAll(`[${this.openTriggerValue}]`)

    modalTriggers.forEach(trigger => {
      // Get the attribute to save.
      const triggerElement = trigger.getAttribute(this.openTriggerValue)

      // Remove the attribute briefly to clear memory/existing modals.
      trigger.removeAttribute(this.openTriggerValue)

      // Immediately add it back.
      trigger.setAttribute(this.openTriggerValue, triggerElement)

      // Re-initialize.
      this.#initModals()
    })
  }
}
