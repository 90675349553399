import { Controller } from "@hotwired/stimulus"
import { gsap, Power4, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ["button", "iframe", "image", "gradient", "content"]

  initialize () {
    this.player = ""
    this.done = false
    const tag = document.createElement('script')
    tag.src = "https://www.youtube.com/iframe_api"
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }

  beginAnimation () {
    this.tl = gsap.timeline({ delay: 0.7, onComplete: this.createIframeYoutube.bind(this) })
    this.tl.to(this.imageTarget, { y: "0%", x: "0%", scale: 1, duration: 1, ease: Power4.easeOut }, "common")
    this.tl.to(this.gradientTarget, { opacity: 0, pointerEvents: "none", duration: 1, ease: Power4.easeOut }, "common")
    this.tl.to(this.imageTarget, { opacity: 0, pointerEvents: "none", duration: 0.4, ease: Power4.easeOut }, "next")
    this.tl.to(this.contentTarget, { opacity: 0, pointerEvents: "none", duration: 0.4, ease: Power4.easeOut }, "next")
  }

  createIframeYoutube () {
    // eslint-disable-next-line
    this.player = new YT.Player('player', {
      videoId: 'M7U26r0WHVU',
      playerVars: {
        autoplay: 0,
        controls: 0,
        rel: 0,
        fs: 0
      },
      events: {
        onReady: this.onPlayerReady.bind(this)
      }
    })
  }

  onPlayerReady (event) {
    event.target.playVideo()
  }

  stopVideo () {
    this.player.stopVideo()
  }
}
